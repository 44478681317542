exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bitcoin-100-week-moving-average-index-tsx": () => import("./../../../src/pages/bitcoin-100-week-moving-average/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-100-week-moving-average-index-tsx" */),
  "component---src-pages-bitcoin-20-week-moving-average-chart-index-tsx": () => import("./../../../src/pages/bitcoin-20-week-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-20-week-moving-average-chart-index-tsx" */),
  "component---src-pages-bitcoin-200-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/bitcoin-200-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-200-day-moving-average-chart-index-tsx" */),
  "component---src-pages-bitcoin-21-week-ema-chart-index-tsx": () => import("./../../../src/pages/bitcoin-21-week-ema-chart/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-21-week-ema-chart-index-tsx" */),
  "component---src-pages-bitcoin-50-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/bitcoin-50-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-50-day-moving-average-chart-index-tsx" */),
  "component---src-pages-bitcoin-macd-chart-index-tsx": () => import("./../../../src/pages/bitcoin-macd-chart/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-macd-chart-index-tsx" */),
  "component---src-pages-bitcoin-rsi-chart-index-tsx": () => import("./../../../src/pages/bitcoin-rsi-chart/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-rsi-chart-index-tsx" */),
  "component---src-pages-bitcoin-rsi-index-tsx": () => import("./../../../src/pages/bitcoin-rsi/index.tsx" /* webpackChunkName: "component---src-pages-bitcoin-rsi-index-tsx" */),
  "component---src-pages-bitcoin-tsx": () => import("./../../../src/pages/bitcoin.tsx" /* webpackChunkName: "component---src-pages-bitcoin-tsx" */),
  "component---src-pages-btc-50-200-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/btc-50-200-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-btc-50-200-day-moving-average-chart-index-tsx" */),
  "component---src-pages-eth-50-200-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/eth-50-200-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-eth-50-200-day-moving-average-chart-index-tsx" */),
  "component---src-pages-ethereum-100-week-moving-average-index-tsx": () => import("./../../../src/pages/ethereum-100-week-moving-average/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-100-week-moving-average-index-tsx" */),
  "component---src-pages-ethereum-20-week-moving-average-chart-index-tsx": () => import("./../../../src/pages/ethereum-20-week-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-20-week-moving-average-chart-index-tsx" */),
  "component---src-pages-ethereum-200-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/ethereum-200-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-200-day-moving-average-chart-index-tsx" */),
  "component---src-pages-ethereum-21-week-ema-chart-index-tsx": () => import("./../../../src/pages/ethereum-21-week-ema-chart/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-21-week-ema-chart-index-tsx" */),
  "component---src-pages-ethereum-50-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/ethereum-50-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-50-day-moving-average-chart-index-tsx" */),
  "component---src-pages-ethereum-macd-chart-index-tsx": () => import("./../../../src/pages/ethereum-macd-chart/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-macd-chart-index-tsx" */),
  "component---src-pages-ethereum-rsi-chart-index-tsx": () => import("./../../../src/pages/ethereum-rsi-chart/index.tsx" /* webpackChunkName: "component---src-pages-ethereum-rsi-chart-index-tsx" */),
  "component---src-pages-ethereum-tsx": () => import("./../../../src/pages/ethereum.tsx" /* webpackChunkName: "component---src-pages-ethereum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-macd-tsx": () => import("./../../../src/pages/macd.tsx" /* webpackChunkName: "component---src-pages-macd-tsx" */),
  "component---src-pages-moving-average-tsx": () => import("./../../../src/pages/moving-average.tsx" /* webpackChunkName: "component---src-pages-moving-average-tsx" */),
  "component---src-pages-rsi-tsx": () => import("./../../../src/pages/rsi.tsx" /* webpackChunkName: "component---src-pages-rsi-tsx" */),
  "component---src-pages-sol-50-200-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/sol-50-200-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-sol-50-200-day-moving-average-chart-index-tsx" */),
  "component---src-pages-solana-100-week-moving-average-index-tsx": () => import("./../../../src/pages/solana-100-week-moving-average/index.tsx" /* webpackChunkName: "component---src-pages-solana-100-week-moving-average-index-tsx" */),
  "component---src-pages-solana-20-week-moving-average-chart-index-tsx": () => import("./../../../src/pages/solana-20-week-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-solana-20-week-moving-average-chart-index-tsx" */),
  "component---src-pages-solana-200-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/solana-200-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-solana-200-day-moving-average-chart-index-tsx" */),
  "component---src-pages-solana-21-week-ema-chart-index-tsx": () => import("./../../../src/pages/solana-21-week-ema-chart/index.tsx" /* webpackChunkName: "component---src-pages-solana-21-week-ema-chart-index-tsx" */),
  "component---src-pages-solana-50-day-moving-average-chart-index-tsx": () => import("./../../../src/pages/solana-50-day-moving-average-chart/index.tsx" /* webpackChunkName: "component---src-pages-solana-50-day-moving-average-chart-index-tsx" */),
  "component---src-pages-solana-macd-chart-index-tsx": () => import("./../../../src/pages/solana-macd-chart/index.tsx" /* webpackChunkName: "component---src-pages-solana-macd-chart-index-tsx" */),
  "component---src-pages-solana-rsi-chart-index-tsx": () => import("./../../../src/pages/solana-rsi-chart/index.tsx" /* webpackChunkName: "component---src-pages-solana-rsi-chart-index-tsx" */),
  "component---src-pages-solana-tsx": () => import("./../../../src/pages/solana.tsx" /* webpackChunkName: "component---src-pages-solana-tsx" */)
}

